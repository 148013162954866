import React from "react"
import { Transition } from "@headlessui/react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Главная" />
    <Transition
      appear={true}
      show={true}
      enter="transition-opacity duration-700"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-700"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <section
        id="main"
        aria-labelledby="main-heading"
        className="mx-auto max-w-xl py-6 px-0 sm:py-24 md:px-12 lg:max-w-7xl"
      >
        <h1
          id="main-heading"
          className="text-center text-lg font-extrabold md:text-3xl"
        >
          Выберите необходимый раздел
        </h1>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="space-y-2 bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <Link to="/commercial">
              <div
                className="rounded-lg border border-gray-300 bg-white p-4 text-center text-gray-700 
              hover:bg-indigo-50 hover:font-semibold hover:text-gray-900"
              >
                Коммерческое предложение
              </div>
            </Link>

            <Link to="/r">
              <div
                className="mt-4 rounded-lg border border-gray-300 bg-white p-4 text-center 
              text-gray-700 hover:bg-indigo-50 hover:font-semibold hover:text-gray-900"
              >
                Техническое задание
              </div>
            </Link>

            <a
              href="https://docs.google.com/document/d/1JnLdjY53pQlDBaA1fSM8dr1AHerhKH8e/edit?usp=sharing&ouid=112534100155238366716&rtpof=true&sd=true"
              target="_blank"
              rel="noreferrer"
            >
              <div
                className="mt-4 rounded-lg border border-gray-300 bg-white p-4 text-center 
              text-gray-700 hover:bg-indigo-50 hover:font-semibold hover:text-gray-900"
              >
                Прайс
              </div>
            </a>

            <a
              href="https://drive.google.com/file/d/1OEn3L40yVwNPKgGt2YdY92Je5iaX9b6L/view?usp=drive_link"
              target="_blank"
              rel="noreferrer"
            >
              <div
                className="mt-4 rounded-lg border border-gray-300 bg-white p-4 text-center 
              text-gray-700 hover:bg-indigo-50 hover:font-semibold hover:text-gray-900"
              >
                Памятка для менеджеров
              </div>
            </a>
          </div>
        </div>
      </section>
    </Transition>
  </Layout>
)

export default IndexPage
